let formBtn = document.getElementById("submit");
    formBtn.addEventListener("click", addUtmParaFormFunc, false);
    formBtn.myParam = formBtn;

function addUtmParaFormFunc(event) {
    let btn = event.currentTarget.myParam;
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let utmSource = urlParams.get('utm_source');
    let utmMedium = urlParams.get('utm_medium');
    let utmCampaign = urlParams.get('utm_campaign');

    if ( urlParams.has('utm_source') ) {
        sessionStorage.setItem("utm_source", utmSource);
    }

    if ( urlParams.has('utm_medium') ) {
        sessionStorage.setItem("utm_medium", utmMedium);
    }

    if ( urlParams.has('utm_campaign')  ) {
        sessionStorage.setItem("utm_campaign", utmCampaign);
    }
}
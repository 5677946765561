import * as Globals from './globals_studypages.js'
import ValidationTarget from './ValidationTarget.js';

(function ($) {

    /**
     * insightly plugin
     * preparing formdata to match insightly configuration
     * Sending form to bob-server with insightly endpoint.
     * @returns {jQuery}
     */

    $.fn.bobFormContact = function (options) {

        // Settings
        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }
        let settings = $.extend({}, options);
        let pre = settings.prefix;

        // Set bob constants
        let g = Globals;

        // Load the bobMsg plugin
        $(g.FRAME_FORM_SELECTOR).bobMsg(settings);

        let hasHidden = function () {
            return $(`${settings.classForm} select[name^='hidden']`).length > 0;
        }

        let hasPhone = function () {
            return $(`${settings.classForm} input[name^='phone']`).length > 0;
        }

        let submitForm = function (e) {

            e.preventDefault();

            $('#submit', '.bob-frame').prop('disabled', true);
            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            // This informs the message plugin that a new submit event happend
            // So you always get a fresh new message on each submit
            _.throwEvent(settings.classForm, g.EVENT_FORM_SUBMIT);

            // validate values - extra method
            let valid = validateFormData();

            if (!valid) {
                // enable button again
                _.submitBtnDisabled(pre, false);

                // throw data-invalid-event
                _.throwEvent(settings.classForm, g.EVENT_FORM_WARNING);

                return;
            }

            sendData();

        };

        let sendData = function () {

            let data = prepareData();
            console.log(data)

            $('document').spinner('show');

            $.ajax({
                // hubspot
                url: settings.endpoint,
                type: "POST",
                data: JSON.stringify(data),
                dataType: "json",
                cache: false,
                contentType: "application/json",

                success: function () {

                    // used on relevis
                    if (hasHidden()) {
                        // myObject, myCategory, myAction
                        _.eTracker2(settings.classForm, 'form', data.hidden);
                    } else {
                        _.eTracker2(settings.classForm, 'form', settings.etrackerAction);
                    }

                    _.eTrackerOrder(settings.etrackerId);

                    $('.form-group input', settings.classForm).val('');

                    _.submitBtnDisabled(pre, false);

                    // open a frame and load success content
                    _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                        'src': '#s1-bob-content-success'
                    });

                    $('document').spinner('hide');
                },
                error: function () {

                    _.throwEvent(settings.classForm, g.EVENT_FORM_ERROR);

                    $('document').spinner('hide');
                }
            });

        };


        /**
         * Validate form data and (maybe) modify formData object
         * @returns {boolean}
         */
        let validateFormData = function () {

            $('.form-group input', settings.classForm).removeClass('is-invalid');

            // get form values
            let formData = _.getFormValidationData(settings.classForm);
            let honeypot = formData.checkboxes.datapolicy
            let results = [];
            let validationTargets = [
                formData.values.firstname,
                formData.values.lastname,
                formData.values.email
            ];

            if (hasHidden()) {
                validationTargets.push(formData.values.hidden)
            }

            if (hasPhone()) {
                validationTargets.push(formData.values.phone)
            }

            validationTargets.forEach(function (item) {

                let that = new ValidationTarget(item)

                if (that.hasFailedToValidate()) {
                    that.handleInvalid();
                } else {
                    that.handleValid();
                }

                results.push(that.isValid);
            })

            
                // Honeypot check if checkbox exists
            if (typeof honeypot != 'undefined') {
                if (honeypot.status === true) {          
                    return results.every(el => el === false);
                } else {           
                    return results.every(el => el === true);   
                }
            } else {

                return results.every(el => el === true);
            }
            

        };

        let prepareData = function () {

            let data = _.getFormData(settings.classForm);

            let utm_source = sessionStorage.getItem("utm_source");
            let utm_medium = sessionStorage.getItem("utm_medium");
            let utm_campaign = sessionStorage.getItem("utm_campaign");    

            // dont get confused! values are called data in this method
            let values = _.getFormData(settings.classForm).data;

            // hubspot
            let data_endpoint = {
                "properties": {
                    "email": values.email,
                    "firstname": values.firstname,
                    "lastname": values.lastname,
                    "phone": (values.phone !== "") ? values.phone : 'Tel. nicht angegeben',
                    "utm_source": (utm_source !== null) ? utm_source : 'No source',
                    "utm_medium": (utm_medium !== null) ? utm_medium : 'No medium',
                    "utm_campaign": (utm_campaign !== null) ? utm_campaign : 'No campaign'
                }
            }

            if (hasHidden()) {
                data_endpoint['hidden'] = values.hidden;
            }

            if (_.isObject(settings.release)) {
                data_endpoint.properties.t0_website_version = settings.release.version
            }

            if (values.studypage_design) {
                data_endpoint.properties.studypage_design = values.studypage_design;
            }

            return data_endpoint

        };

        $(document).off('.contact');

        $(document).on('submit.contact', 'form.form-contact', function (e) {
            submitForm(e);
        });

        return this;

    };

}(jQuery));